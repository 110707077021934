import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderMedia id={'service-2'} mdxType="HeaderMedia" />
    <Spacer mdxType="Spacer" />
    <Wrapper width={960} style={{
      textAlign: 'justify'
    }} mdxType="Wrapper">
  <Heading mdxType="Heading">Unser Service</Heading>
      <p>{`  Wir übernehmen folgende Aufgaben für die Wohnungseigentümergemeinschaften`}</p>
      <p>{`  Unsere Aufgaben und Befugnisse richten sich generell natürlich nach dem Wohnungseigentumsgesetz (WEG) und dem
Bürgerlichen Gesetzbucs (BGB), der sog. Gemeinschaftsordnung, also insbesondere den Regelungen der Teilungserklärung,
den weiteren nach §10 Absatz 2 WEG getroffenen Vereinbarungen der Wohnungseigentümergemeinschaft und den Beschlüssen
der Wohnungseigentümergemeinschaft.`}</p>
      <p>{`  Im Einzelnen handelt sich hierbei um folgende grundsätzliche Tätigkeiten:`}</p>
      <p>{`  Die Eigentümerversammlung mindestens einmal im Jahre einzuberufen;`}</p>
      <p>{`  Die Beschlüsse der Eigentümer durchzuführen und für die Durchführung der Hausordnung zu sorgen;`}</p>
      <p>{`  Die Niederschriften über die Beschlüsse der Eigentümerversamlung sowie die richterlichen Entscheidungen nach § 43 WEG
ordnungsgemäß zu fertigen und aufzubewahren;`}</p>
      <p>{`  Jeweils rechtzeitig einen Wirtschaftsplan aufzustellen und nach Ablauf des Wirtschaftsjahres die Jahresabrechnung zu
fertigen und beides der Gemeinschaft zur Beschlußfassung vorzulegen;`}</p>
      <p>{`  Die für die ordnungsmäßige Instandhaltung und Instandsetzung des gemeinschaftlichen Eigentums die erforderlichen
Maßnahmen bis zu einem festzulegenden Betrag zu treffen, bzw. mit Einwilligung des Beirats bis zu einem festzulegenden
Betrag zu treffen, darüber hinaus ist ein WEG-Beschluss erforderlich.`}</p>
      <p>{`  Die für die ordnungsmäßige Instandhaltung und Instandsetzung erforderlichen Maßnahmen größeren Umfanges so
vorzubereiten, daß die Gemeinschaft die notwendigen Beschlüsse vor Durchführung der Maßnahmen fassen kann;`}</p>
      <p>{`  In dringenden Fällen sonstige zur Erhaltung des gemeinschaftlichen Eigentums erforderliche Maßnahmen zu treffen;`}</p>
      <p>{`  Die nachfolgenden aufgeführten Versicherungen aufrechtzuerhalten, zu kündigen, neu abzuschließen oder ggf. so zu
ändern, dass die Wohnanlage stets ausreichend versichert ist. Anpassungen sowie Umstellungen auf andere Versicherer
darf der Verwalter mit Rücksprache mit dem Verwaltungsbeirat ohne ausdrücklichen Beschluss der Eigentümergemeinschaft
unmittelbar vornehmen, wenn verbesserte wirtschaftliche oder vertragliche Inhalte für die Gemeinschaft möglich sind.
Dies gilt insbesondere für:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`die verbundenen Wohngebäudeversicherung (Feuer, Leitungswasser, Sturm),`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`die Haus- und Grundbesitzerhaftpflichtversicherung`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`die Gewässerschadenversicherung für Öltanks und Ähnliches (falls erforderlich)`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`auf Beschluss der Eigentümergemeinschaft eine Elementarschadenversicherung,`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`auf Beschluss der Eigentümergemeinschaft bei großen Bauvorhaben eine Bauherrenhaftpflicht- und Bauwesenversicherung
und`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`auf Beschluss der Eigentümergemeinschaft eine Vermögensschaden-Haftpflichtversicherungfür Beiräte`}</p>
          <p parentName="li">{`Bei Verstößen gegen die Hausordnung müssen wir als Verwalter tätig werden, wenn wir selbst Zeuge der Verstöße geworden
sind oder uns die Verstöße schriftlich durch Wohnungseigentümer gemeldet werden. Bei schriftlich gemeldeten Verstößen
gegen die Hausordnung müssen wir, als Verwaltung, die Störer schriftlich unter Angabe des Beschwerdeführers abmahnen
und bei Erfolgslosigkeit dieser Maßnahme die Wohnungseigentümergemeinschaft auf der nächsten Eigentümerversammlung
unterrichten. Ist der Störer Mieter, ist der Eigentümer aufzufordern, seine Mieter abzumahnen, um die mietrechtlich
notwendigen Schritte einzuleiten.`}</p>
          <p parentName="li">{`Wir sind als Verwalter zur ordnungsmäßigen Geldanlage des Verwaltungsvermögens, insbesondere der
Instandhaltungsrückstellung verpflichtet. Wir haben die Verwaltung der gemeinschaftlichen Gelder mit der Sorgfalt
eines Verwalters von Wohnungs- bzw. Geschäftseigentum zu betreiben; nicht aber als Anlageberater oder Bankier. Das
gemeinschaftliche Vermögen haben wir, als Verwaltung, von unserem Vermögen getrennt zu halten. Über die Verwendung der
gemeinschaftlichen Gelder ist von uns als Verwaltung Rechnung zu legen.`}</p>
          <p parentName="li">{`Nach der neuen WEG-Novelle § 24 VII sind wir verpflichtet eine Beschlusssammlung für Beschlüsse, die gefasst werden zu
führen.`}</p>
          <p parentName="li">{`Unsere Verwaltung hat eine angemessene Vermögensschadenhaftpflichtversicherung abgeschlossen und wir sind verpflichtet
diese dauerhaft zu unterhalten.`}</p>
          <p parentName="li">{`Für die Aufbewahrung der im Besitz des Verwalters befindlichen Unterlagen gelten die kaufmännischen
Aufbewahrungspflichten (§257 HGB) mit der sechs bzw. zehnjährigen Frist entsprechend. Protokolle der
Eigentümergemeinschaft werden von uns auf keinen Fall vernichtet.`}</p>
          <p parentName="li">{`Im Rahmen unserer Tätigkeiten sind wir ebenfalls berechtigt`}</p>
          <p parentName="li">{`Lasten- und Kostenbeträge in Empfang zu nehmen und abzuführen;`}</p>
          <p parentName="li">{`alle Zahlungen und Leistungen zu bewirken und entgegenzunehmen, die mit der laufenden Verwaltung des
gemeinschaftlichen Eigentums zusammenhängen;`}</p>
          <p parentName="li">{`Willenserklärungen und Zustellungen entgegenzunehmen, soweit sie an alle Eigentümer in dieser Eigenschaft gerichtet
sind;`}</p>
          <p parentName="li">{`Maßnahmen zu treffen, die zur Wahrung einer Frist oder zur Abwendung eines sonstigen Rechtsnachteils erforderlich
sind;`}</p>
          <p parentName="li">{`die Gemeinschaft gerichtlich (sowohl im Aktiv- wie auch im Passivprozeß) und außergerichtlich in Angelegenheiten der
laufenden Verwaltung zu vertreten und in diesem Zusammenhang bestehende Ansprüche durchzusetzen; sofern wir hierzu
durch Beschluss der Wohnungs- bzw. Geschäftseigentümer ermächtigt wurden;`}</p>
          <h2 parentName="li" {...{
            "id": "maklertatigkeit"
          }}>{`Maklertätigkeit`}</h2>
          <p parentName="li">{`Wir haben seit Mitte 2016 unser Angebot erweitert und bieten seither eine weitere Dienstleistung an.`}</p>
          <p parentName="li">{`Nach dem Erhalt der Erlaubnis gemäß § 34 c Gewerbeordnung (GewO) Ennepe-Ruhr-Kreis bieten wir auch den Verkauf und die
Vermietung von Immobilien an. Dieses Angebot richtet sich sowohl an unsere Kunden, sowie an nicht von uns verwaltete
Objekte. Mittlerweile wurden mehrere Fremd- aufträge erfolgreich zum Abschluss gebracht.`}</p>
          <p parentName="li">{`Wir sehen Ihre Immobilie aus dem Blickwinkel einer erfahrenen Hausverwaltung mit Fachkompetenz sowie praktischen
Erfahrungswerten. Der Vorteil für unsere Kunden liegt eindeutig darin, dass wir bereits über einen Großteil der
erforderlichen Daten und Informationen zu ihren Immobilien verfügen und somit den Arbeitsumfang für Sie gering halten
können.`}</p>
          <p parentName="li">{`Profitieren Sie dabei von folgenden Vorteilen:`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Unterstützung bei der Ermittlung des Verkaufspreises Ihrer Immobilie`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Erstellung eines professionellen Exposés sowie einer detaillierten Objekt- und Lagebeschreibung`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Präsentation Ihres Objektes in den gängigen Online-Immobilienportalen, auf unser WEB-Site sowie in der regionalen
Presse`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Durchführung von Besichtigungen mit qualifizierten Interessenten`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Bonitätsprüfungen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Vorbereitung des Mietvertrages`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`oder Vorbereitung und Zusammenstellung der Daten für den Kaufvertrag in Abstimmung mit dem jeweiligen Notar`}</p>
          <p parentName="li">{`Bitte nutzen Sie unser Kontaktformular und sprechen Sie uns an.`}</p>
        </li>
      </ul>
    </Wrapper>
    <Spacer mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      